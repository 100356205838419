
import { msalInstance } from "../index";

// export async function getAccessToken(scopes) {
//     const account = msalInstance.getActiveAccount();
//     if (!account) {
//         throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
//     }
//     const response = await msalInstance.acquireTokenSilent({
//         ...scopes,
//         account: account
//     });

//     return response.accessToken;
// }

export async function getIdToken(scopes) {
    const account = msalInstance.getActiveAccount();
    const  { exp } = account.idTokenClaims;
    const expDate= new Date(exp * 1000 - 5000*60), now = new Date();
    const forceRefresh = (expDate<now);
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    const response = await msalInstance.acquireTokenSilent({
        ...scopes,
        account: account,
        forceRefresh: forceRefresh
    });

    return response.idToken;
}
import { Box, Typography } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { useTranslation } from "react-i18next";
const FileFieldDisplayComponent = ({ isError }) => {
  const { t } = useTranslation();
  return (
    <div
      className="fileBox"
      style={{ borderColor: isError ? "red" : "rgb(213, 218, 215)" }}
    >
      <div className="box box-2">
        <Box>
          <CloudUploadOutlinedIcon fontSize="large" />
        </Box>
        <Box sx={{ fontWeight: 20, fontSize: 16 }}>{t("chooseAFileText")}</Box>
        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
          {t("orDragItHereText")}
        </Typography>
      </div>
    </div>
  );
};

export default FileFieldDisplayComponent;

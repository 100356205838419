import { Button, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { OtherRequestFormData } from "../../models/forms/OtherRequestFormData";
import {
  errorData,
  submitData,
  updateSubmitData,
} from "../../redux/submitData/reducer";
import FileFieldDisplayComponent from "./FileDisplayComponent";
import { FormProps } from "./FormProps";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { dataFromApi } from "../../redux/getApiData/reducer";
export const OtherRequestForm: React.FC<FormProps> = ({
  handleOnSubmit,
  validators,
}) => {
  const apiData = useAppSelector(dataFromApi);
  const dispatch = useAppDispatch();
  const formData = useAppSelector(submitData);
  const formFieldData = formData?.data as OtherRequestFormData;
  const formErrorData = useAppSelector(errorData);
  const { t } = useTranslation();
  function handleSubmitData() {
    var noteNotValidated = !validators.requiredValidator(
      "note",
      formFieldData?.note
    );
    let totalSize = 0;
    for (const fileElement of formFieldData?.attachment
      ? formFieldData?.attachment
      : []) {
      totalSize = totalSize + fileElement.size;
    }
    var fileNotValidated = !(
      validators.requiredValidator("attachment", formFieldData?.attachment) &&
      validators.minLengthValidator(
        "attachment",
        formFieldData?.attachment,
        1,
        "requiredError"
      ) &&
      validators.maxLengthValidator(
        "attachment",
        formFieldData?.attachment,
        4,
        "numberOfFileError"
      ) &&
      validators.fileSizeValidator("attachment", formFieldData?.attachment)
    );

    if (noteNotValidated || fileNotValidated) {
      console.log("Data is not validated");
    } else {
      handleOnSubmit(true);
    }
  }
  return (
    <>
      {apiData ? (
        <>
          <Typography color="secondary"
            sx={{
              fontSize: 16,
              fontWeight: 700,
              fontFamily: "Open Sans",
              lineHeight: "22px"
            }}>
            {t("urgentMessage")}
          </Typography>
          {formFieldData?.attachment &&
          Array.from(formFieldData?.attachment).length >= 4 ? null : (
            <FileUploader
              multiple
              handleChange={(file) => {
                let totalSize = 0;
                for (const fileElement of [
                  ...file,
                  ...(formFieldData?.attachment
                    ? formFieldData?.attachment
                    : []),
                ]) {
                  totalSize = totalSize + fileElement.size;
                }
                validators.minLengthValidator(
                  "attachment",
                  [
                    ...file,
                    ...(formFieldData?.attachment
                      ? formFieldData?.attachment
                      : []),
                  ],
                  1,
                  "requiredError"
                ) &&
                  validators.maxLengthValidator(
                    "attachment",
                    [
                      ...file,
                      ...(formFieldData?.attachment
                        ? formFieldData?.attachment
                        : []),
                    ],
                    4,
                    "numberOfFileError"
                  ) &&
                  validators.fileSizeValidator("attachment", totalSize);
                dispatch(
                  updateSubmitData({
                    key: "attachment",
                    value: [
                      ...file,
                      ...(formFieldData?.attachment
                        ? formFieldData?.attachment
                        : []),
                    ],
                  })
                );
              }}
              value={
                formFieldData?.attachment ? formFieldData?.attachment : null
              }
              label="Attachment"
              name="file"
              children={
                <FileFieldDisplayComponent
                  isError={formErrorData?.attachment?.isError}
                />
              }
            />
          )}

          {formFieldData?.attachment &&
          Array.from(formFieldData?.attachment).length > 0 ? (
            <>
              <List sx={{ width: "100%", border: "1px" }}>
                {Array.from(formFieldData?.attachment).map((file, index) => (
                  <ListItem
                    disableGutters
                    secondaryAction={
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          const newFileList = Array.from(
                            formFieldData?.attachment
                          ).filter((item, index) => item != file);
                          let totalSize = 0;
                          for (const fileElement of newFileList) {
                            totalSize = totalSize + fileElement.size;
                          }
                          validators.minLengthValidator(
                            "attachment",
                            newFileList,
                            1,
                            "requiredError"
                          ) &&
                            validators.maxLengthValidator(
                              "attachment",
                              newFileList,
                              4,
                              "numberOfFileError"
                            ) &&
                            validators.fileSizeValidator(
                              "attachment",
                              totalSize
                            );
                          dispatch(
                            updateSubmitData({
                              key: "attachment",
                              value: newFileList,
                            })
                          );
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    }
                    sx={{
                      border: "1px solid rgb(213, 218, 215)",
                      borderRadius: "5px",
                      marginBottom: "20px",
                    }}
                  >
                    <ListItemText
                      primary={file.name}
                      sx={{
                        marginLeft: "10px",
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </>
          ) : null}

          {formErrorData?.attachment?.isError ? (
            <p
              style={{
                color: "red",
                fontSize: "0.75rem",
                textAlign: "left",
              }}
            >
              *{t(formErrorData?.attachment?.errorMessage)}
            </p>
          ) : null}
          <TextField
            label={t("noteTextFieldLabel")}
            placeholder={t("noteTextFieldPlaceHolder")}
            required
            multiline
            rows={5}
            onChange={(event) => {
              validators.requiredValidator("note", event.target?.value);
              dispatch(
                updateSubmitData({ key: "note", value: event.target?.value })
              );
            }}
            value={formFieldData?.note ? formFieldData?.note : ""}
            helperText={
              formErrorData?.note?.isError && t(formErrorData?.note?.errorMessage)
            }
            error={formErrorData?.note?.isError}
          />
          <Button
            variant="contained"
            onClick={handleSubmitData}
            style={{
              marginBottom: "4vh",
              marginTop: "4vh",
              backgroundColor: "#8F201B",
              color: "#FFFFFF",
              borderRadius: "100px",
              textTransform: "none"
            }}
          >
            {t("submit")}
          </Button>
        </>
      ) : null}
    </>
  );
};

import { Alert, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { msalInstance } from "../index";
import { ManageSharedMailboxesForm } from "../components/form-components/ManageSharedMailboxesForm";
import { ManageSiteUserForm } from "../components/form-components/ManageSiteUsersForm";
import { NewSiteTeamRequestForm } from "../components/form-components/NewSiteTeamRequestForm";
import { OffboardEmployeeForm } from "../components/form-components/OffboardEmployeeForm";
import { OnboardEmployeeForm } from "../components/form-components/OnboardEmployee";
import { OtherRequestForm } from "../components/form-components/OtherRequestForm";
import { FORM_TYPE, ROUTES } from "../constant";
import "../styles/css/styles.css";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../hooks/hook";
import {
  isSubmitLoading,
  resetErrorData,
  setErrorData,
  setSubmitData,
  submitDataAsync,
} from "../redux/submitData/reducer";
import { Loading } from "../components/Loading";
import { dataFromApi } from "../redux/getApiData/reducer";
import { OnBoardEmployeeFormData } from "../models/forms/OnBoardEmployeeFormData";
import { OffBoardEmployeeFormData } from "../models/forms/OffBoardEmployeeFormData";
import { ManageSharedMailboxFormData } from "../models/forms/ManageSharedMailboxFormData";
import { ManageSharePointSiteFormData } from "../models/forms/ManageSharePointSiteFormData";
import { NewSiteTeamRequestFormData } from "../models/forms/NewSiteTeamRequestFormData";
import { OtherRequestFormData } from "../models/forms/OtherRequestFormData";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { useTranslation } from "react-i18next";
import { authScopes } from "../authConfig";
import { AccountInfo } from "@azure/msal-common";

export function CustomForm() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const apiData = useAppSelector(dataFromApi);
  const type = new URLSearchParams(search).get("type");
  const account = msalInstance.getActiveAccount();
  const [formTypeData, setFormTypeData] = useState({
    TITLE: "",
    TYPE: "",
    ICON: PersonAddAltOutlinedIcon,
  });
  const isLoading = useAppSelector(isSubmitLoading);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState<
    "info" | "success" | "error" | "warning"
  >("info");
  const { t } = useTranslation();
  useEffect(() => {
    var formType = Object.values(FORM_TYPE).filter((formType) => {
      return formType.TYPE === type;
    });
    if (formType.length > 0) {
      setFormTypeData(formType[0]);
      dispatch(
        setSubmitData({
          type: formType[0].TYPE,
          requester: account.username,
          tenant: apiData?.name,
          ...(formType[0] == FORM_TYPE.ONBOARD_EMPLOYEE && {
            data: {
              name: "",
              jobTitle: "",
              manager: account.name,
              startDate: dayjs(new Date()),
            } as OnBoardEmployeeFormData,
          }),
          ...(formType[0] == FORM_TYPE.OFFBOARD_EMPLOYEE && {
            data: {
              employee: "",
              teminationDatetime: dayjs(new Date()),
              outOfOfficeMessage: "N",
              accessToArchivedMaibox: "N",
            } as OffBoardEmployeeFormData,
          }),
          ...(formType[0] == FORM_TYPE.MANAGE_SHARED_MAILBOXES && {
            data: {
              sharedMailbox: "",
              username: [],
              sendAs: "",
              action: "add",
            } as ManageSharedMailboxFormData,
          }),
          ...(formType[0] == FORM_TYPE.MANAGE_SITE_USERS && {
            data: {
              sharepointSite: "",
              username: [],
              levelOfPermission: "",
              action: "add",
            } as ManageSharePointSiteFormData,
          }),
          ...(formType[0] == FORM_TYPE.NEW_SITE_TEAM && {
            data: {
              siteOrTeam: "site",
              name: "",
              owner: [],
            } as NewSiteTeamRequestFormData,
          }),
          ...(formType[0] == FORM_TYPE.OTHER_REQUEST && {
            data: {
              note: "",
              attachment: null,
            } as OtherRequestFormData,
          }),
        })
      );
      dispatch(resetErrorData());
    } else {
      navigate(ROUTES.NOT_FOUND);
    }
  }, [type]);

  function handleOnSubmit(useMultiplePart: boolean) {
    dispatch(submitDataAsync({ useMultiplePart: useMultiplePart })).then(
      (requestStatus) => {
        if (requestStatus?.meta.requestStatus == "fulfilled") {
          dispatch(
            setSubmitData({
              type: formTypeData.TYPE,
              requester: account.username,
              tenant: apiData?.name,
              ...(formTypeData == FORM_TYPE.ONBOARD_EMPLOYEE && {
                data: {
                  name: "",
                  jobTitle: "",
                  manager: account.name,
                  startDate: dayjs(new Date()),
                } as OnBoardEmployeeFormData,
              }),
              ...(formTypeData == FORM_TYPE.OFFBOARD_EMPLOYEE && {
                data: {
                  employee: "",
                  teminationDatetime: dayjs(new Date()),
                  outOfOfficeMessage: "N",
                  accessToArchivedMaibox: "N",
                } as OffBoardEmployeeFormData,
              }),
              ...(formTypeData == FORM_TYPE.MANAGE_SHARED_MAILBOXES && {
                data: {
                  sharedMailbox: "",
                  username: [],
                  sendAs: "",
                  action: "add",
                } as ManageSharedMailboxFormData,
              }),
              ...(formTypeData == FORM_TYPE.MANAGE_SITE_USERS && {
                data: {
                  sharepointSite: "",
                  username: [],
                  levelOfPermission: "",
                  action: "add",
                } as ManageSharePointSiteFormData,
              }),
              ...(formTypeData == FORM_TYPE.NEW_SITE_TEAM && {
                data: {
                  siteOrTeam: "site",
                  name: "",
                  owner: [],
                } as NewSiteTeamRequestFormData,
              }),
              ...(formTypeData == FORM_TYPE.OTHER_REQUEST && {
                data: {
                  note: "",
                  attachment: null,
                } as OtherRequestFormData,
              }),
            })
          );
          dispatch(resetErrorData());
          setMessage("submitSuccessMessage");
          setMessageType("success");
          setShowMessage(true);
        } else if (requestStatus?.meta.requestStatus == "rejected") {
          const error = JSON.parse(requestStatus.payload?.message);
          if (error.message === "InteractionRequiredAuthError") {
            msalInstance.acquireTokenRedirect({
              ...authScopes,
              account: msalInstance.getActiveAccount() as AccountInfo,
            });
          }
          setMessage("submitFailedMessage");
          setMessageType("error");
          setShowMessage(true);
        }

        setTimeout(() => {
          setShowMessage(false);
          setMessage("");
        }, 5000);
      }
    );
  }
  const requiredValidator = (key, value) => {
    if (!value) {
      dispatch(
        setErrorData({
          key: key,
          value: { errorMessage: "requiredError", isError: true },
        })
      );
      return false;
    }
    dispatch(
      setErrorData({
        key: key,
        value: { errorMessage: "", isError: false },
      })
    );
    return true;
  };
  const minLengthValidator = (key, value, length, message?) => {
    if (value.length < length) {
      dispatch(
        setErrorData({
          key: key,
          value: {
            errorMessage: message
              ? message
              : "minLengthError",
            isError: true,
          },
        })
      );
      return false;
    }
    dispatch(
      setErrorData({ key: key, value: { errorMessage: "", isError: false } })
    );
    return true;
  };

  const maxLengthValidator = (key, value, length, message?) => {
    if (value.length > length) {
      dispatch(
        setErrorData({
          key: key,
          value: {
            errorMessage: message,
            isError: true,
          },
        })
      );
      return false;
    }
    dispatch(
      setErrorData({ key: key, value: { errorMessage: "", isError: false } })
    );
    return true;
  };

  const fileSizeValidator = (key, sizeInBytes) => {
    var sizeInMB = parseInt((sizeInBytes / (1024 * 1024)).toFixed(2));
    if (sizeInMB > 40) {
      dispatch(
        setErrorData({
          key: key,
          value: {
            errorMessage: "fileSizeError",
            isError: true,
          },
        })
      );
      return false;
    }
    dispatch(
      setErrorData({ key: key, value: { errorMessage: "", isError: false } })
    );
    return true;
  };

  // const regexValidator = (key, value, regex, message) => {
  //   if (!regex.test(value)) {
  //     dispatch(
  //       setErrorData({
  //         key: key,
  //         value: {
  //           errorMessage: message,
  //           isError: true,
  //         },
  //       })
  //     );
  //     return false;
  //   }
  //   dispatch(
  //     setErrorData({ key: key, value: { errorMessage: "", isError: false } })
  //   );
  //   return true;
  // };
  return (
    <>
      <>
        {isLoading ? <Loading /> : null}

        <Stack
          spacing={3}
          sx={{ width: "60%", paddingTop: "20px" }}
          display={"inline-flex"}
          flexDirection={"column"}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {
              <formTypeData.ICON
                fontSize="large"
                color="secondary"
              />
            }
            <Typography
            color="secondary"
              style={{
                fontFamily: "Poppins",
                fontSize: 24,
                fontWeight: 400,
                lineHeight: "36px",
              }}
            >
              {" "}
              {t(formTypeData.TITLE)}
            </Typography>
          </Stack>

          {showMessage && message ? (
            <Alert
              severity={messageType}
              sx={{ width: "100%" }}
              variant="outlined"
            >
              {t(message)}
            </Alert>
          ) : null}
          {formTypeData == FORM_TYPE.ONBOARD_EMPLOYEE ? (
            <OnboardEmployeeForm
              handleOnSubmit={handleOnSubmit}
              validators={{
                requiredValidator: requiredValidator,
                minLengthValidator: minLengthValidator,
                maxLengthValidator: maxLengthValidator,
                fileSizeValidator: fileSizeValidator,
              }}
            />
          ) : null}
          {formTypeData == FORM_TYPE.OFFBOARD_EMPLOYEE ? (
            <OffboardEmployeeForm
              handleOnSubmit={handleOnSubmit}
              validators={{
                requiredValidator: requiredValidator,
              }}
            />
          ) : null}
          {formTypeData == FORM_TYPE.NEW_SITE_TEAM ? (
            <NewSiteTeamRequestForm
              handleOnSubmit={handleOnSubmit}
              validators={{
                requiredValidator: requiredValidator,
                minLengthValidator: minLengthValidator,
              }}
            />
          ) : null}
          {formTypeData == FORM_TYPE.MANAGE_SHARED_MAILBOXES ? (
            <ManageSharedMailboxesForm
              handleOnSubmit={handleOnSubmit}
              validators={{
                requiredValidator: requiredValidator,
                minLengthValidator: minLengthValidator
              }}
            />
          ) : null}
          {formTypeData == FORM_TYPE.MANAGE_SITE_USERS ? (
            <ManageSiteUserForm
              handleOnSubmit={handleOnSubmit}
              validators={{
                requiredValidator: requiredValidator,
                minLengthValidator: minLengthValidator
              }}
            />
          ) : null}
          {formTypeData == FORM_TYPE.OTHER_REQUEST ? (
            <OtherRequestForm
              handleOnSubmit={handleOnSubmit}
              validators={{
                requiredValidator: requiredValidator,
                maxLengthValidator: maxLengthValidator,
                fileSizeValidator: fileSizeValidator,
                minLengthValidator: minLengthValidator,
              }}
            />
          ) : null}
          {/* <Button variant="contained" onClick={handleOnSubmit}>
              Submit
            </Button> */}
        </Stack>
      </>
    </>
  );
}

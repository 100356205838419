import { useState } from "react";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import WelcomeName from "./WelcomeName";
import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SideBarData from "../routers/sidebar";
import AppBar from "./AppBar";
import { ROUTES } from "../constant";
import { dataFromApi } from "../redux/getApiData/reducer";
import { useAppSelector } from "../hooks/hook";
import logo from "../images/074aca08e8f34889806806557004a2a9.png";
import { useTranslation } from "react-i18next";
import { hasReportPermission } from "../redux/getEmbedConfig/reducer";

type Props = {
  children?: React.ReactNode;
};

export const PersistentDrawerLeft: React.FC<Props> = ({ children }) => {
  const apiData = useAppSelector(dataFromApi);
  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
  };
  const hasReportAccessPermission = useAppSelector(hasReportPermission);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="appbar-background"
      >
        <Toolbar
          variant="dense"
          disableGutters
          sx={{ minHeight: 112, height: 112 }}
        >
          <img src={logo} alt="logo" className="logo" />
          <Typography
            color="secondary"
            style={{
              flexGrow: 1,
              paddingLeft: "15px",
              marginTop: "30px",
            }}
          >
            <Link
              component={RouterLink}
              to={ROUTES.HOME}
              color="inherit"
              variant="h6"
              underline="none"
              fontFamily="Poppins"
              fontSize="40px"
              fontWeight="300"
              onClick={() => handleListItemClick(0)}
            >
              central view
            </Link>
          </Typography>
          <WelcomeName />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          flexShrink: 0,
        }}
        className="sidebar"
      >
        <Toolbar
          variant="dense"
          disableGutters
          sx={{ minHeight: 112, height: 112 }}
        />
        {/* Company Name */}
        <Box sx={{ padding: 2 }}>
          <Typography
            variant="h6"
            color="secondary"
            sx={{
              fontSize: 24,
              fontWeight: 700,
              fontFamily: "Poppins",
              lineHeight: "36px",
            }}
            className="tenant-text"
          >
            {apiData?.name}
          </Typography>
        </Box>

        {/* Divider */}

        <Divider variant="middle" />

        {/* Submit a Ticket Section */}
        <Box sx={{ overflow: "auto", paddingLeft: "16px" }}>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{
              fontSize: "16px",
              fontFamily: "Open Sans",
              fontWeight: "700",
            }}
            className="sidebar-title"
          >
            {t("formSideBarTitle")}
          </Typography>
          <List
            sx={{
              // selected and (selected + hover) states
              "&& .Mui-selected, && .Mui-selected:hover": {
                bgcolor: "rgba(143, 32, 27, 0.15)",
              },
              // hover states
              "& .MuiListItemButton-root:hover": {
                bgcolor: "rgba(143, 32, 27, 0.15)",
              },
            }}
          >
            {SideBarData.formSideBar.map((item, index) => (
              <ListItem key={t(item.title)} disablePadding>
                <ListItemButton
                  component={RouterLink}
                  to={item.route}
                  selected={selectedIndex === index + 1}
                  onClick={() => handleListItemClick(index + 1)}
                >
                  <ListItemIcon>{<item.icon />}</ListItemIcon>
                  <ListItemText
                    primary={t(item.title)}
                    primaryTypographyProps={{
                      fontSize: "16px",
                      fontFamily: "Open Sans",
                      fontWeight: "400",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>

        {/* Divider */}
        {hasReportAccessPermission ? (
          <>
            <Divider variant="middle" />

            {/* View My Reports Section */}
            <Box sx={{ overflow: "auto", paddingLeft: "16px" }}>
              <Typography
                variant="subtitle1"
                color="secondary"
                sx={{
                  color: "#8F201B",
                  fontSize: "16px",
                  fontFamily: "Open Sans",
                  fontWeight: "700",
                }}
                className="sidebar-title"
              >
                {t("reportSideBarTitle")}
              </Typography>
              <List
                sx={{
                  // selected and (selected + hover) states
                  "&& .Mui-selected, && .Mui-selected:hover": {
                    bgcolor: "rgba(143, 32, 27, 0.15)",
                  },
                  // hover states
                  "& .MuiListItemButton-root:hover": {
                    bgcolor: "rgba(143, 32, 27, 0.15)",
                  },
                }}
              >
                {SideBarData.reportSideBar.map((item, index) => (
                  <ListItem key={t(item.title)} disablePadding>
                    <ListItemButton
                      component={RouterLink}
                      to={item.route}
                      selected={
                        selectedIndex ===
                        index + 1 + SideBarData.formSideBar.length
                      }
                      onClick={() =>
                        handleListItemClick(
                          index + 1 + SideBarData.formSideBar.length
                        )
                      }
                    >
                      <ListItemIcon>{<item.icon />}</ListItemIcon>
                      <ListItemText
                        primary={t(item.title)}
                        primaryTypographyProps={{
                          fontSize: "16px",
                          fontFamily: "Open Sans",
                          fontWeight: "400",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </>
        ) : null}
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Toolbar
          variant="dense"
          disableGutters
          sx={{ minHeight: 112, height: 112 }}
        />
        <div className="container">{children}</div>
      </Box>
    </Box>
  );
};

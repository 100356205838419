import { InteractionRequiredAuthError } from "@azure/msal-common";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authScopes, protectedResources } from "../../authConfig";
import { callApi } from "../../helpers/call_api";
import { getIdToken } from "../../helpers/msal_auth";
import { RootState } from "../store";
import { SubmitData } from "../../models/SubmitData";
import { ErrorData } from "../../models/ErrorData";

export interface SubmitState {
  error: any;
  data: SubmitData | null;
  isLoading: boolean;
}

export type UpdateDict = {
  key: string;
  value: any;
  delete?: boolean;
};
export type FormErrorData = {
  key: string;
  value: ErrorData;
};
const initialState: SubmitState = {
  error: {},
  data: null,
  isLoading: false,
};

export const submitDataAsync = createAsyncThunk(
  "submitData/submit",
  async (
    payload: { useMultiplePart: boolean },
    { rejectWithValue, getState }
  ) => {
    try {
      var { submitData } = getState() as { submitData: { data: SubmitData } };
      let idToken = await getIdToken(authScopes);
      var body: any;
      if (payload.useMultiplePart) {
        const formData = new FormData();
        for (const key in submitData.data) {
          if (key == "data") {
            for (const data in submitData.data[key]) {
              if (data == "attachment") {
                const attachments = [...submitData.data[key][data]];
                for (const file in attachments) {
                  formData.append(data, attachments[file]);
                }
              } else {
                formData.append(data, submitData.data[key][data]);
              }
            }
          } else {
            formData.append(key, submitData.data[key]);
          }
        }
        body = formData;
      } else {
        let extend_with_data = { ...submitData.data, ...submitData.data.data };
        delete extend_with_data.data;
        body = JSON.stringify(extend_with_data);
      }
      await callApi(
        idToken,
        protectedResources.supportApi + "/queue",
        "POST",
        body
      );
    } catch (e) {
      var error = e;
      if (e instanceof InteractionRequiredAuthError) {
        error = new Error(
          JSON.stringify({
            status: 403,
            message: "InteractionRequiredAuthError",
          })
        );
      }
      try {
        var _ = JSON.parse(error.message);
      } catch (objError) {
        if (objError instanceof SyntaxError) {
          error = new Error(
            JSON.stringify({
              status: 500,
              message: error.message,
            })
          );
        }
      }
      return rejectWithValue(error);
    }
  }
);

export const submitDataSlice = createSlice({
  name: "submitData",
  initialState,
  reducers: {
    setSubmitData: (state, action: PayloadAction<SubmitData>) => {
      state.data = action.payload;
    },
    updateSubmitData: (state, action: PayloadAction<UpdateDict>) => {
      var needUpdateSubmitData = state.data;
      if (action.payload.delete) {
        delete needUpdateSubmitData.data[action.payload.key];
      } else {
        needUpdateSubmitData.data[action.payload.key] = action.payload.value;
      }
      state.data = needUpdateSubmitData;
    },
    setErrorData: (state, action: PayloadAction<FormErrorData>) => {
      var needUpdateErrorData = state.error;
      needUpdateErrorData[action.payload.key] = action.payload.value;
      state.error = needUpdateErrorData;
    },
    resetErrorData: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitDataAsync.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(submitDataAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(submitDataAsync.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { setSubmitData, updateSubmitData, setErrorData, resetErrorData } =
  submitDataSlice.actions;

export const submitData = (state: RootState) => state.submitData.data;

export const errorData = (state: RootState) => state.submitData.error;

export const isSubmitLoading = (state: RootState) => state.submitData.isLoading;

export default submitDataSlice.reducer;

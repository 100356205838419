import { useEffect } from "react";
import { PersistentDrawerLeft } from "./SideBar";
import { useAppDispatch, useAppSelector } from "../hooks/hook";
import { getDataAsync, isDataLoading } from "../redux/getApiData/reducer";
import { Loading } from "./Loading";
import { Index } from "../pages/Index";
import { msalInstance } from "../index";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { AccountInfo } from "@azure/msal-common";
import { authScopes } from "../authConfig";
import { useNavigate } from "react-router-dom";
import { getEmbedReportAsync, loading } from "../redux/getEmbedConfig/reducer";

type Props = {
  children?: React.ReactNode;
};

export const PageLayout: React.FC<Props> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { inProgress } = useMsal();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(isDataLoading);
  const isReportLoading = useAppSelector(loading);
  useEffect(() => {
    if (inProgress === InteractionStatus.None && isAuthenticated) {
      dispatch(getDataAsync()).then((requestStatus) => {
        if (requestStatus?.meta.requestStatus == "fulfilled") {

        } else if (requestStatus?.meta.requestStatus == "rejected") {
          const error = JSON.parse(requestStatus.payload?.message);
          console.log(error)
          if (error.message === "InteractionRequiredAuthError") {
            msalInstance.acquireTokenRedirect({
              ...authScopes,
              account: msalInstance.getActiveAccount() as AccountInfo,
            });
          } else {
            if (error.status === 404 || error.status === 403) {
              navigate("/error",  { state: { message: 'tenantNotSupport' } })
            }
            if (error.status === 500) {
              navigate("/error",  { state: { message: 'internalServerError' } })
            }
          }
        }
      });
      dispatch(getEmbedReportAsync()).then((requestStatus) => {
        if (requestStatus?.meta.requestStatus == "fulfilled") {

        } else if (requestStatus?.meta.requestStatus == "rejected") {
          const error = JSON.parse(requestStatus.payload?.message);
          console.log(error)
          if (error.message === "InteractionRequiredAuthError") {
            msalInstance.acquireTokenRedirect({
              ...authScopes,
              account: msalInstance.getActiveAccount() as AccountInfo,
            });
          } else {
            if (error.status === 403 || error.status === 401) {
              navigate("/error",  { state: { message: 'tenantNotSupport' } })
            }
          }
        }
      });
    }
  }, [inProgress, msalInstance]);

  return (
    <>
      {isLoading || isReportLoading ? (
        <Loading />
      ) : (
        <>
          {isAuthenticated ? (
            <>
              <PersistentDrawerLeft>{children}</PersistentDrawerLeft>
            </>
          ) : (
            <Index />
          )}
        </>
      )}
    </>
  );
};

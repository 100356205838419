// Msal imports
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { authScopes } from "../authConfig";

// Sample app imports
import { Loading } from "../components/Loading";
import { ErrorComponent } from "../components/ErrorComponent";
import { Home } from "./Home";

export function Index() {
  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authScopes}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      >
        <Home />
      </MsalAuthenticationTemplate>
    </>
  );
}

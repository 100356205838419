import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// Create a theme instance.
export const theme = createTheme({
  
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#8F201B',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#f9f9f9',
    }
  },
  components: {
    // Inputs
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `1px solid #23A5EB`
            }
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
              color: "#23A5EB"
            }
          }
        }
      }
    }
});
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { msalInstance } from "../index";
import logo from "../images/074aca08e8f34889806806557004a2a9.png";

export function Error() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { message } = state;
  const account = msalInstance.getActiveAccount();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Dialog
          open={true}
          sx={{
            backdropFilter: "blur(5px)",
          }}
        >
          <DialogTitle
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={logo} alt="logo" className="logo" style={{width:"150px"}} />
            <Typography variant="h5" color="error" sx={{ mt: 2 }}>
              Error!
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ textAlign: "center" }}>
            <Typography variant="body1" sx={{ fontSize: 18 }}>
              {t(message, {tenantName: account.username?.split("@").at(-1).split(".")[0]})} 
            </Typography>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
}

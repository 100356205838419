import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { ManageSharedMailboxFormData } from "../../models/forms/ManageSharedMailboxFormData";
import { dataFromApi } from "../../redux/getApiData/reducer";
import {
  errorData,
  submitData,
  updateSubmitData,
} from "../../redux/submitData/reducer";
import { FormProps } from "./FormProps";

export const ManageSharedMailboxesForm: React.FC<FormProps> = ({
  handleOnSubmit,
  validators,
}) => {
  const apiData = useAppSelector(dataFromApi);
  const dispatch = useAppDispatch();
  const formData = useAppSelector(submitData);
  const formFieldData = formData?.data as ManageSharedMailboxFormData;
  const formErrorData = useAppSelector(errorData);
  const { t } = useTranslation();
  function handleSubmitData() {
    var sharedMailboxNotValidated = !validators.requiredValidator(
      "sharedMailbox",
      formFieldData?.sharedMailbox
    );

    var usernameNotValidated = !validators.minLengthValidator(
        "username",
        formFieldData?.username,
        1,
        "requiredError"
      )
    if (sharedMailboxNotValidated || usernameNotValidated) {
      console.log("Data is not validated");
    } else {
      handleOnSubmit(false);
    }
  }
  return (
    <>
      {apiData ? (
        <>
          <FormControl >
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={formFieldData?.action}
              onChange={(event, value) =>
                dispatch(updateSubmitData({ key: "action", value: value }))
              }
              sx={{justifyContent: "center"}}
            >
              <FormControlLabel
                value="add"
                control={<Radio color="secondary" />}
                label={t("add")}
                sx={{paddingRight: "10vh"}}
              />
              <FormControlLabel
                value="remove"
                control={<Radio color="secondary" />}
                label={t("remove")}
              />
            </RadioGroup>
          </FormControl>
          <Autocomplete
            id="tags-standard"
            options={apiData.tenant.sharedMailboxes}
            onChange={(event, value) => {
              validators.requiredValidator("sharedMailbox", value);
              dispatch(
                updateSubmitData({ key: "sharedMailbox", value: value })
              );
            }}
            value={
              formFieldData?.sharedMailbox ? formFieldData?.sharedMailbox : null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={t("sharedMailboxTextFieldLabel")}
                placeholder={t("sharedMailboxTextFieldPlaceHolder")}
                error={formErrorData?.sharedMailbox?.isError}
                helperText={
                  formErrorData?.sharedMailbox?.isError &&
                  t(formErrorData?.sharedMailbox?.errorMessage)
                }
              />
            )}
          />
          <Autocomplete
            multiple
            id="tags-standard"
            options={apiData.tenant.users}
            filterSelectedOptions
            value={formFieldData?.username ? formFieldData?.username : []}
            onChange={(event, value) => {
              validators.minLengthValidator(
                "username",
                value,
                1,
                "requiredError"
              )
              dispatch(updateSubmitData({ key: "username", value: value }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("usernameTextFieldLabel")}
                placeholder={t("usernameTextFieldPlaceHolder")}
                required
                error={formErrorData?.username?.isError}
                helperText={
                  formErrorData?.username?.isError &&
                  t(formErrorData?.username?.errorMessage)
                }
              />
            )}
          />
          <FormGroup style={{ width: "100%", display: "contents"  }}>
            <FormControlLabel
              style={{marginTop: "12px"}}
              control={
                <Checkbox
                  color="secondary"
                  onChange={(_, checked) => {
                    var value = "N";
                    if (checked) {
                      value = "Y";
                    }
                    dispatch(
                      updateSubmitData({
                        key: "sendAs",
                        value: value,
                      })
                    );
                  }}
                  checked={formFieldData?.sendAs == "Y" ? true : false}
                />
              }
              label={
                <Typography variant={"subtitle2"}>{t("sendAs")}?</Typography>
              }
            />
          </FormGroup>
          <TextField
            style={{marginTop: "12px"}}
            label={t("noteTextFieldLabel")}
            placeholder={t("noteTextFieldPlaceHolder")}
            multiline
            rows={5}
            onChange={(event) =>
              dispatch(
                updateSubmitData({ key: "note", value: event.target?.value })
              )
            }
            value={formFieldData?.note ? formFieldData?.note : ""}
          />
          <Button
            variant="contained"
            onClick={handleSubmitData}
            style={{
              marginBottom: "4vh",
              marginTop: "4vh",
              backgroundColor: "#8F201B",
              color: "#FFFFFF",
              borderRadius: "100px",
              textTransform: "none"
            }}
          >
            {t("submit")}
          </Button>
        </>
      ) : null}
    </>
  );
};

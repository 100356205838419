import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { OffBoardEmployeeFormData } from "../../models/forms/OffBoardEmployeeFormData";
import { dataFromApi } from "../../redux/getApiData/reducer";
import {
  errorData,
  submitData,
  updateSubmitData,
} from "../../redux/submitData/reducer";
import { FormProps } from "./FormProps";

export const OffboardEmployeeForm: React.FC<FormProps> = ({
  handleOnSubmit,
  validators,
}) => {
  const apiData = useAppSelector(dataFromApi);
  const dispatch = useAppDispatch();
  const formData = useAppSelector(submitData);
  const formFieldData = formData?.data as OffBoardEmployeeFormData;
  const formErrorData = useAppSelector(errorData);
  const { t } = useTranslation();
  function handleSubmitData() {
    var employeeNotValidated = !validators.requiredValidator(
      "employee",
      formFieldData?.employee
    );
    var teminationDatetimeNotValidated = !validators.requiredValidator(
      "teminationDatetime",
      formFieldData?.teminationDatetime
    );
    var messageNotValidated = false;
    if (formFieldData?.outOfOfficeMessage == "Y") {
      messageNotValidated = !validators.requiredValidator(
        "message",
        formFieldData?.message
      );
    }

    var recipientNotValidated = false;
    if (formFieldData?.accessToArchivedMaibox == "Y") {
      recipientNotValidated = !validators.requiredValidator(
        "recipient",
        formFieldData?.recipient
      );
    }

    if (
      employeeNotValidated ||
      teminationDatetimeNotValidated ||
      messageNotValidated ||
      recipientNotValidated
    ) {
      console.log("Data is not validated");
    } else {
      if (formFieldData?.outOfOfficeMessage == "N") {
        dispatch(updateSubmitData({ key: "message", value: "N/A" }));
      }
      if (formFieldData?.accessToArchivedMaibox == "N") {
        dispatch(updateSubmitData({ key: "recipient", value: "N/A" }));
      }

      handleOnSubmit(false);
    }
  }
  return (
    <>
      {apiData && formData?.data ? (
        <>
          <Autocomplete
            id="tags-standard"
            options={apiData.tenant.users}
            value={formFieldData?.employee ? formFieldData?.employee : null}
            filterSelectedOptions
            onChange={(event, value) => {
              validators.requiredValidator("employee", value);
              dispatch(updateSubmitData({ key: "employee", value: value }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={t("employeeTextFieldLabel")}
                placeholder={t("employeeTextFieldPlaceHolder")}
                error={formErrorData?.employee?.isError}
                helperText={
                  formErrorData?.employee?.isError &&
                  t(formErrorData?.employee?.errorMessage)
                }
              />
            )}
          />
          <Stack direction="row" spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t("terminateDateTextFieldLabel")}
                value={formFieldData?.teminationDatetime}
                onChange={(value) =>
                  dispatch(
                    updateSubmitData({
                      key: "teminationDatetime",
                      value: value,
                    })
                  )
                }
                slotProps={{
                  textField: {
                    fullWidth: true,
                    helperText:
                      formErrorData?.teminationDatetime?.isError &&
                      t(formErrorData?.teminationDatetime?.errorMessage),
                    error: formErrorData?.teminationDatetime?.isError,
                  },
                }}
              />
              <TimePicker
                label={t("terminateTimeTextFieldLabel")}
                value={formFieldData?.teminationDatetime}
                onChange={(value) =>
                  dispatch(
                    updateSubmitData({
                      key: "teminationDatetime",
                      value: value,
                    })
                  )
                }
              />
            </LocalizationProvider>
          </Stack>
          <FormGroup style={{ width: "100%", display: "contents" }}>
            <FormControlLabel
              style={{marginTop: "12px"}}
              control={
                <Checkbox
                  color="secondary"
                  onChange={(_, checked) => {
                    var value = "N";
                    if (checked) {
                      value = "Y";
                    }
                    dispatch(
                      updateSubmitData({
                        key: "outOfOfficeMessage",
                        value: value,
                      })
                    );
                  }}
                  checked={
                    formFieldData?.outOfOfficeMessage == "Y" ? true : false
                  }
                />
              }
              label={
                <Typography variant={"subtitle2"}>
                  {t("outOfOfficeMessage")}?
                </Typography>
              }
            />
          </FormGroup>
          {formFieldData?.outOfOfficeMessage &&
          formFieldData?.outOfOfficeMessage == "Y" ? (
            <TextField
              style={{marginTop: "11px"}}
              label={t("messageTextFieldLabel")}
              placeholder={t("messageTextFieldPlaceholder")}
              multiline
              rows={5}
              onChange={(event) => {
                validators.requiredValidator("message", event.target?.value);
                dispatch(
                  updateSubmitData({
                    key: "message",
                    value: event.target?.value,
                  })
                );
              }}
              error={formErrorData?.message?.isError}
              helperText={
                formErrorData?.message?.isError &&
                t(formErrorData?.message?.errorMessage)
              }
            />
          ) : null}
          <FormGroup style={{ width: "100%" , display: "contents" }}>
            <FormControlLabel
            style={formFieldData?.outOfOfficeMessage &&
              formFieldData?.outOfOfficeMessage == "Y" ? {marginTop: "12px"}: {marginTop: "-2px"}}
              control={
                <Checkbox
                  onChange={(_, checked) => {
                    var value = "N";
                    if (checked) {
                      value = "Y";
                    }
                    dispatch(
                      updateSubmitData({
                        key: "accessToArchivedMaibox",
                        value: value,
                      })
                    );
                  }}
                  checked={
                    formFieldData?.accessToArchivedMaibox == "Y" ? true : false
                  }
                  color="secondary"
                />
              }
              label={
                <Typography variant={"subtitle2"}>
                  {t("fullAccessToArchivedMailbox")}?
                </Typography>
              }
            />
          </FormGroup>
          {formFieldData?.accessToArchivedMaibox &&
          formFieldData?.accessToArchivedMaibox == "Y" ? (
            <Autocomplete

              style={{marginTop: "11px"}}
              id="tags-standard"
              options={apiData.tenant.sharedMailboxes}
              value={formFieldData?.recipient ? formFieldData?.recipient : null}
              filterSelectedOptions
              onChange={(event, value) => {
                validators.requiredValidator("recipient", value);
                dispatch(updateSubmitData({ key: "recipient", value: value }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={t("recipientTextFieldLabel")}
                  placeholder={t("recipientTextFieldPlaceholder")}
                  error={formErrorData?.recipient?.isError}
                  helperText={
                    formErrorData?.recipient?.isError &&
                    t(formErrorData?.recipient?.errorMessage)
                  }
                />
              )}
            />
          ) : null}
          <TextField
            style={formFieldData?.accessToArchivedMaibox &&
              formFieldData?.accessToArchivedMaibox == "Y" ? {}: {marginTop: "11px"}}
            label={t("noteTextFieldLabel")}
            placeholder={t("noteTextFieldPlaceHolder")}
            multiline
            rows={5}
            onChange={(event) =>
              dispatch(
                updateSubmitData({ key: "note", value: event.target?.value })
              )
            }
            value={formFieldData?.note ? formFieldData?.note : ""}
          />
          <Button
            variant="contained"
            onClick={handleSubmitData}
            style={{
              marginBottom: "4vh",
              marginTop: "4vh",
              backgroundColor: "#8F201B",
              color: "#FFFFFF",
              borderRadius: "100px",
              textTransform: "none"
            }}
          >
            {t("submit")}
          </Button>
        </>
      ) : null}
    </>
  );
};

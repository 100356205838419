import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import dataReducer from './getApiData/reducer'
import submitReducer from './submitData/reducer'
import embedConfigReducer from './getEmbedConfig/reducer'

export const store = configureStore({
  reducer: {
    apiData: dataReducer,
    submitData: submitReducer,
    embedConfig: embedConfigReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

import { Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack } from '@mui/system';


export const Loading = () => {
    return <>
        <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1, position: "absolute" }}
            open={true}
          >
            <Stack gap={1} justifyContent="center" alignItems="center">
              <CircularProgress color="inherit" />
              <Typography>Loading...</Typography>
            </Stack>
          </Backdrop>
    </>
}
export async function callApi(
  token: string,
  url: string,
  method: string,
  body?: any
) {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);
  const options = {
    method: method,
    headers: headers,
    ...(body && {
      body: body,
    }),
  };
  return fetch(url, options).then((response) => {
    if (!response.ok) {
      return response.json().then(errorResponse => {
        throw new Error(JSON.stringify({
          status: response.status,
          message: errorResponse
        }));
      })
    }
    return response.json()
  });
}

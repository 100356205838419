import { Configuration, PopupRequest } from "@azure/msal-browser";

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito
// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI,
        redirectUri: process.env.REACT_APP_REDIRECT_URI
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE || isEdge || isFirefox,
        secureCookies: isIE || isEdge || isFirefox,
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const authScopes: PopupRequest = {
    scopes: ["User.Read"]
};

export const powerBIScopes: PopupRequest = {
    scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"]
};

export const protectedResources = {
    supportApi: process.env.REACT_APP_SUPPORT_API
}


import { Grid, Typography } from "@mui/material";
import homeImage from "../images/62b3caf85c4d5be7e6422a7abd03f31e.png";
import { useTranslation } from "react-i18next";

export function Home() {
  const { t } = useTranslation();
  return (
    <>
    <Grid container justifyContent="flex-start">
    <Grid item xs={12}>
    <Typography
          sx={{
            color: "#333333",
            fontSize: "24px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textAlign: "left",
            paddingLeft: "15px",
            paddingTop: "15px"
          }}
        >
          {t("homeText1")}
        </Typography>
  </Grid>
  
  </Grid>
  <Grid container justifyContent="flex-start">
    <Grid item xs={12}>
    <Typography
          sx={{
            color: "#333333",
            fontSize: "16px",
            fontFamily: "Open Sans",
            fontWeight: "400",
            textAlign: "left",
            paddingLeft: "15px",
          }}
        >
          {t("homeText2")}
        </Typography>
  </Grid>
  </Grid>
        
      <img
        src={homeImage}
        alt="homeImage"
        style={{
          width: "100%",
          height: "70vh",
          objectFit: "cover",
          objectPosition: "5vh 0",
        }}
      />
    </>
  );
}

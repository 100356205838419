import { Routes, Route, useNavigate } from "react-router-dom";
// Material-UI imports
import Grid from "@mui/material/Grid";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./utils/NavigationClient";

import { PageLayout } from "./components/PageLayout";
import { Home } from "./pages/Home";
import { NotFound } from "./pages/NotFound";
import { ReportsComponent } from "./pages/Reports";
import { SecurityDashboard } from "./pages/SecurityDashboard";
import { LicenseDashboard } from "./pages/LicenseDashboard";
import { IdentityDashboard } from "./pages/IdentityDashboard";
import { EmailDashboard } from "./pages/EmailDashboard";
import { ROUTES } from "./constant";
import { CustomForm } from "./pages/Form";
import { Index } from "./pages/Index";
import { Error } from "./pages/ErrorPage";

type AppProps = {
    pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);

    return (
        <MsalProvider instance={pca}>
            <PageLayout>
                <Grid container justifyContent="center">
                    <Pages />
                </Grid>
            </PageLayout>
        </MsalProvider>
    );
}

function Pages() {
    return (
        <Routes>
            <Route path={ROUTES.REPORTS} element={<ReportsComponent />} />
            <Route path={ROUTES.SECURITYDASHBOARD} element={<SecurityDashboard />} />
            <Route path={ROUTES.LICENSEDASHBOARD} element={<LicenseDashboard />} />
            <Route path={ROUTES.IDENTITYDASHBOARD} element={<IdentityDashboard />} />
            <Route path={ROUTES.EMAILDASHBOARD} element={<EmailDashboard />} />
            <Route path={ROUTES.FORMS} element={<CustomForm />} />
            <Route path={ROUTES.ERROR} element={<Error />} />
            <Route path={ROUTES.HOME} element={<Index />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default App;

import React, { useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../styles/css/powerbi.css";
import { useAppSelector } from "../hooks/hook";
import { loading, embedConfig } from "../redux/getEmbedConfig/reducer";
import { Loading } from "../components/Loading";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";


export function SecurityDashboard() {
  const isLoading = useAppSelector(loading);
  const myEmbedConfig = useAppSelector(embedConfig);
  const { t } = useTranslation();
  const [isReportLoading, setIsReportLoading] = useState(true);

  const handleLoaded = () => {
    setIsReportLoading(false);
    const iframe = document.querySelector('.report-container-2 iframe');
    iframe.style.visibility = 'visible';
  };

  return (
    <>
      {isLoading ? <Loading /> : <>
        {myEmbedConfig ? <>
         {isReportLoading && <div className="spinner">
                               <div></div>
                               <div></div>
                               <div></div>
                               <div></div>
                             </div>}
        <PowerBIEmbed
          embedConfig={{
            type: "report", // Supported types: report, dashboard, tile, visual, qna and paginated report
            id: myEmbedConfig.id,
            pageName: "ReportSectiona6c83693e1ee0db250b6",
            embedUrl: myEmbedConfig.embedUrl,
            accessToken: myEmbedConfig.embeddedToken,
            tokenType: models.TokenType.Embed,
            settings: {
              authoringHintsEnabled: false,
              background: models.BackgroundType.Default,
              bars: {
                statusBar: { visible: false},
                actionBar: { visible: false}
              },
              bookmarksPaneEnabled: false,
              customLayout: {
                displayOption: models.DisplayOption.FitToWidth,
              },
              filterPaneEnabled: false,
              hyperlinkClickBehavior: models.HyperlinkClickBehavior.Navigate,
              navContentPaneEnabled: false,
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
              persistentFiltersEnabled: false,
              layoutType: models.LayoutType.Custom

            },
          }}
          cssClassName={"report-container-2"}
          eventHandlers={
            new Map([
              [
                "rendered",
                function () {
                  handleLoaded();
                },
              ],
            ])
          }
        />
        </> : <Typography>{t("cannotLoadReport")}</Typography>

      }
      </>}

    </>
  );
}

import {  useEffect } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../styles/css/powerbi.css";
import { useAppSelector } from "../hooks/hook";
import { loading, embedConfig } from "../redux/getEmbedConfig/reducer"
import { Loading } from "../components/Loading";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {CommandDisplayOption, IPaginatedReportsCommandsSettings, LayoutType} from "powerbi-models";


export function ReportsComponent() {
  const isLoading = useAppSelector(loading);
  const myEmbedConfig = useAppSelector(embedConfig);
  const { t } = useTranslation();

  return (
    <>
      {isLoading ? <Loading /> : <>
        {myEmbedConfig ? <><PowerBIEmbed
          embedConfig={{
            type: "report", // Supported types: report, dashboard, tile, visual, qna and paginated report
            id: myEmbedConfig.id,
            pageName: "ReportSection3f335680ae07dc1b1e17",
            embedUrl: myEmbedConfig.embedUrl,
            accessToken: myEmbedConfig.embeddedToken,
            tokenType: models.TokenType.Embed,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
              layoutType: models.LayoutType.Custom,
              customLayout: {
                displayOption: models.DisplayOption.FitToWidth,
              }
            },
          }}
          cssClassName={"report-container"}
        /></> : <Typography>{t("cannotLoadReport")}</Typography>
        
      }
      </>}
      
    </>
  );
}

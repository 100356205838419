import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import translationEnglish from "../translation/english/translation.json";
import translationSpanish from "../translation/spanish/translation.json";
import translationFrench from "../translation/french/translation.json";


const resources = {
    en: {
        translation: translationEnglish
    },
    es: {
        translation: translationSpanish,
    },
    fr: {
        translation: translationFrench,
    },
}

i18next
.use(initReactI18next)
.init({
  resources,
  lng:"en", //default language
});

export default i18next;
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { msalInstance } from "../index";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Divider, Link, ListItemIcon, Stack } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const WelcomeName = () => {
  const account = msalInstance.getActiveAccount();
  const [name, setName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { t, i18n } = useTranslation();
  const languages = ["EN", "FR", "ES"];
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onClickLanguageChange = (language, index) => {
    setSelectedIndex(index);
    i18n.changeLanguage(language); //change the language
  };
  useEffect(() => {
    if (account && account.name) {
      setName(account.name);
    } else {
      setName("");
    }
  }, [account]);
  const handleLogout = () => {
    msalInstance.logoutRedirect();
  };
  if (name) {
    return (
      <div>
        <Stack display={"inline-flex"} flexDirection={"column"}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            alignItems="flex-end"
            className="language"
            divider={<Divider orientation="vertical" flexItem />}
          >
            {languages.map((item, index) => (
              <Typography key={item}>
                <Link
                  color="inherit"
                  variant="h6"
                  underline="none"
                  fontFamily="Open Sans"
                  fontSize="12px"
                  style={{cursor: "pointer"}}
                  fontWeight={selectedIndex === index ? "700" : "300"}
                  onClick={() =>
                    onClickLanguageChange(item.toLowerCase(), index)
                  }
                >
                  {item}
                </Link>
              </Typography>
            ))}
          </Stack>
          <IconButton
            size="large"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            style={{ marginRight: "50px", marginTop: "15px" }}
          >
            <AccountCircle fontSize="large" />
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Open Sans",
                fontWeight: "700",
              }}
              className="user-name"
            >
              {name}
            </Typography>
            <ArrowDropDownIcon fontSize="large" />
          </IconButton>
        </Stack>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: { width: anchorEl && anchorEl.offsetWidth }, // <-- The line that does all
          }}
        >
          <MenuItem onClick={handleLogout}>
            {" "}
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            {t("logout")}
          </MenuItem>
        </Menu>
      </div>
    );
  } else {
    return null;
  }
};

export default WelcomeName;
